import React from 'react'
import Helmet from 'react-helmet'
import { Link } from 'gatsby'
import Layout from '../components/layout'
import HeaderGeneric from '../components/HeaderGeneric'
import pic02 from '../assets/images/pic01.jpg'

const btnStyle = {
  backgroundColor: '#d065b5',
  color: 'white',
  fontWeight: 'bold',
  borderColor: 'white',
  width: '100%',
}
const btnWrapStyle = {
  width: '92%',
  textAlign: 'center',
  position: 'absolute',
  margin: 'auto',
  marginTop: '-3rem',
}

class OnDemand extends React.Component {
  render() {
    return (
      <Layout>
        <Helmet title="Future Transit, Thunder Bay Transit Services, tbtfuture.fyi" />
        <HeaderGeneric title="Alternative On-demand Service" />

        <div id="main">
          <section id="intro" className="main">
            <div className="spotlight">
              <div className="content">
                <header className="major">
                  <h2>What is an alternative on-demand service?</h2>
                </header>
                <p>
                  Alternative on-demand service offers public transit in areas
                  with few riders. On-demand service has been used in many
                  cities across North America who face similar barriers to run
                  public transit in spread out communities.
                </p>
              </div>
              <div className="image-w-button">
                <span className="image">
                  <img src={pic02} alt="" />
                  <div style={btnWrapStyle}>
                    <Link to="/map" className="button" style={btnStyle}>
                      View the map
                    </Link>
                  </div>
                </span>
              </div>
            </div>
            <div className="spotlight">
              <div className="content">
                <h2>How would the alternative on-demand service work?</h2>
                <p>
                  Riders would be picked-up from a set location and taken to a
                  nearby transfer hub. This works both ways -- riders can book a
                  trip from a transfer hub to their destination. At the hub,
                  riders can transfer onto the regular fixed-route network
                  without extra charge.
                </p>
              </div>
            </div>
          </section>

          <section id="methods" className="main special">
            <header className="major">
              <h2>
                What areas of the City would benefit from on-demand service?
              </h2>
              <p>
                Several neighbourhoods and wards would benefit from on-demand
                service. They include:
              </p>
            </header>
            <ul className="features">
              <li>
                <span className="icon major style1 fa-bus" />
                <h3>Parkdale</h3>
                <p>&nbsp;</p>
              </li>
              <li>
                <span className="icon major style3 fa-bus" />
                <h3>Neebing Ward</h3>
                <p>&nbsp;</p>
              </li>
              <li>
                <span className="icon major style5 fa-bus" />
                <h3>Fort William First Nation</h3>
                <p>&nbsp;</p>
              </li>
            </ul>
          </section>

          <section id="whats-next" className="main special">
            <header className="major">
              <h2>How do I learn more?</h2>
              <p>
                If you ride on route 4 or 6 or live within the Neebing and Fort
                William areas, we are having meetings to discuss the on-demand
                service. The meetings will be held at the following dates and
                times:
              </p>
            </header>
            <ul className="statistics">
              <li className="style1">
                <span className="icon fa-calendar" />
                <strong>
                  Feb 5<br />
                  7pm
                </strong>
                <p>West Arthur Community Centre</p>
              </li>
              <li className="style2">
                <span className="icon fa-calendar" />
                <strong>
                  Feb 6<br />
                  5pm
                </strong>
                <p>FWFN Community Centre </p>
              </li>
            </ul>
            <p>
              <Link to="/" className="button">
                return to main page...
              </Link>
            </p>
          </section>
        </div>
      </Layout>
    )
  }
}

export default OnDemand
